<template>
  <div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="流程进度" name="first">
        <wf-flow :flow="flow" v-if="activeName == 'first'"></wf-flow>
      </el-tab-pane>
      <el-tab-pane label="流程图" name="second">
        <wf-design ref="bpmn"
                   v-if="activeName == 'second'"
                   style="height: 60vh;"
                   :options="bpmnOption"></wf-design>
      </el-tab-pane>
    </el-tabs>
  </div>

</template>
<script>

import WfFlow from "@/views/plugin/workflow/process/components/flow.vue";

export default {
  name: 'bpmn-flow',
  props: {
    bpmnOption: {
      type: Object,
    },
    flow: {
      type: Object,
    },
  },
  components:{ WfFlow},
  data() {
    return {
      name: '',
      activeName: 'first',
      circulationVisible: false,
      processVisible: false,
      bpmnVisible: false,
    }
  },
}
</script>
<style lang="scss">
</style>
